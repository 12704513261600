<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
<!--        <query-item label="商品编号">-->
<!--          <a-input v-model="query.code" placeholder="商品编号"></a-input>-->
<!--        </query-item>-->
        <query-item label="商品分类">
          <j-category-select
            style="width: 160px"
            placeholder="请选择分类"
            v-model="query.categoryid"
          />
        </query-item>
        <query-item label="商品名称">
          <a-input v-model="query.name" placeholder="商品名称"></a-input>
        </query-item>
        <query-item label="状态">
          <a-select v-model="query.status" >
            <a-select-option :value="0">
              启用
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
          </a-select>
        </query-item>

      </template>
      <!-- 查询条件结束 -->
      <div class="query__summary" slot="summary">
        <a-button class="mr-10" type="primary" icon="download" @click="goodsLogExport">导出</a-button>
      </div>
      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { goodsStatus, goodsDiscountFlag, goodsList, goodsLogExport } from '@/api/goods';
import queryMixin from '@/mixin/queryMixin';
import JCategorySelect from '@/components/JCategorySelect';
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil';
import { loadCategoryData } from '@/api/manage';
export default {
  name: 'GoodsList',
  mixins: [queryMixin],
  components: {
    JCategorySelect
  },
  props: {},
  data() {
    return {
      query: {
        code: null,
        name: null,
        status: null,
        categoryid:null
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          width: 200,
        },
        // {
        //   title: '编码',
        //   dataIndex: 'code',
        //   key: 'code',
        //   ellipsis: true,
        //   width: 200,
        // },
        {
          title: '成本价格',
          dataIndex: 'costPrice',
          key: 'costPrice',
          ellipsis: true,
          width: 200,
        },
        {
          title: '销售价格',
          dataIndex: 'salePrice',
          key: 'salePrice',
          ellipsis: true,
          width: 200,
        },
        // {
        //   title: '规格',
        //   dataIndex: 'spec',
        //   key: 'spec',
        //   ellipsis: true,
        //   width: 200,
        // },
        // {
        //   title: '单位',
        //   dataIndex: 'unit',
        //   key: 'unit',
        //   ellipsis: true,
        //   width: 200,
        // },
        {
          title: '库存',
          dataIndex: 'balance',
          key: 'balance',
          ellipsis: true,
          width: 200,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 150,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '享受会员折扣',
          dataIndex: 'discountFlag',
          key: 'discountFlag',
          width: 150,
          customRender: (text, row) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.discountFlag === 0} onClick={ () => this.handleDiscountFlag(row)}/>
            );
          }
        },
        {
          title: '分类',
          align: 'center',
          dataIndex: 'categoryId',
          width: 200,
          customRender: (text) => (text ? filterMultiDictText(this.dictOptions.categoryid, text) : ''),
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children: <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
              </section>,
            }
          }
        },
      ],
      dictOptions: {},
      dataList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
    this.initDictConfig();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      this.dataList = [];
      goodsList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.productId
        });
        this.dataList = list;
        // console.log(this.dataList)
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'goodsAdd'
      });
    },

    handleInfo(row) {
      this.$router.push({
        name: 'goodsInfo',
        params: {
          id: row.productId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'goodsEdit',
        params: {
          id: row.productId
        }
      });
    },
    handleStatus(row) {
      const params = {
        productId: row.productId,
        status: row.status === 0 ? 1 : 0
      };

      goodsStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    handleDiscountFlag(row) {
      const params = {
        productId: row.productId,
        discountFlag: row.discountFlag === 0 ? 1 : 0
      };

      goodsDiscountFlag(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    goodsLogExport() {
      goodsLogExport().then((data) => {
        window.open(data);
      });
    },
    initDictConfig() {
      loadCategoryData({ code: '0' }).then((res) => {
        // console.log(res)
        if (res !== undefined) {
          this.$set(this.dictOptions, 'categoryid', res);
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.query__summary {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .query__summary__title {
    margin-right: 16px;
    font-weight: bold;
  }
  .query__summary__group {
    display: flex;
    margin-right: 16px;
    .query__summary--value {
      min-width: 20px;
    }
  }
}
</style>
